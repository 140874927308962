import logo from "./logo.svg";
import "./App.css";
import Login from "./Authentication/Login";
import SignUp from "./Authentication/SignUp";
import ConfirmReg from "./Authentication/ConfirmReg";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Navigate replace to="/login" />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<SignUp />} />
          <Route path="confirm" element={<ConfirmReg />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
