import React, { useState, useEffect } from "react";
import { authenticate, confirmEmail, resendEmail } from "./AuthService";
import { useSearchParams, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import VerificationInput from "react-verification-input";
const ConfirmReg = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [searchParams] = useSearchParams();
  const [emailSent, setEmailSent] = useState("");

  const handleLogin = async () => {
    try {
      console.log(username);
      const session = await confirmEmail(username, code);
      navigate(`/login?username=${username}`);
    } catch (err) {
      setError(err.message);
    }
  };
  const resendEmailHandler = async () => {
    try {
      console.log(username);
      const session = await resendEmail(username);
      setEmailSent("Email has been successfully sent");
    } catch (err) {
      setError(err.message);
    }
  };
  useEffect(() => {
    setUsername(searchParams.get("username"));
    code && handleLogin();
  }, [code]);
  return (
    <div className="login">
      <div className="logo_div">
        <img src={logo} className="logo" alt="LingThings' logo" />
      </div>
      <p className="loginText">A verification code has been sent</p>
      <VerificationInput
        classNames={{
          container: "container",
          character: "character",
          characterInactive: "character--inactive",
          characterSelected: "character--selected",
          characterFilled: "character--filled",
        }}
        validChars="0-9"
        placeholder="-"
        onComplete={(val) => {
          setCode(val);
        }}
      />

      <button onClick={() => resendEmailHandler()} className="register_button">
        Re-send email
      </button>
      {emailSent && <p className="req_met">{emailSent}</p>}
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default ConfirmReg;
