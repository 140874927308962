import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";
const poolData = {
  UserPoolId: "eu-west-3_DGXvhI6qc", // Your user pool id here
  ClientId: "cni4ifuc79t16n792okm8jiai", // Your client id here
};
const userPool = new CognitoUserPool(poolData);

export const authenticate = async (username, password) => {
  const authenticationDetails = new AuthenticationDetails({
    Username: username,
    Password: password,
  });

  const userData = {
    Username: username,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);

  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        const accessToken = result.getAccessToken().getJwtToken();
        const refreshToken = result.getRefreshToken().token;
        resolve({ accessToken, refreshToken });
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
};

export const registerUser = async (email, phone, username, password) => {
  function validateUsername() {
    const re = /^[a-zA-Z0-9_.]{3,16}$/;
    return re.test(username);
  }
  var attributeList = [];
  var dataEmail = {
    Name: "email",
    Value: email,
  };
  if (phone) {
    var dataPhone = {
      Name: "phone_number",
      Value: phone,
    };
    var attributePhone = new CognitoUserAttribute(dataPhone);
    attributeList.push(attributePhone);
  }

  var attributeEmail = new CognitoUserAttribute(dataEmail);

  attributeList.push(attributeEmail);

  return new Promise((resolve, reject) => {
    var isValideUsername = validateUsername(email);
    isValideUsername
      ? console.log("ok")
      : (() => {
          reject({
            message: "Username can only contain letters, numbers, . and  _",
          });
          return;
        })();
    if (!isValideUsername) {
      return;
    }
    userPool.signUp(
      username,
      password,
      attributeList,
      null,
      function (err, result) {
        if (err) {
          console.log(err);
          reject(err);
          return;
        }
        var cognitoUser = result.user;
        console.log("user name is " + cognitoUser.getUsername());
        resolve();
      }
    );
  });
};

export const confirmEmail = async (username, code) => {
  var userData = {
    Username: username,
    Pool: userPool,
  };
  var cognitoUser = new CognitoUser(userData);
  return new Promise((resolve, reject) => {
    cognitoUser.confirmRegistration(code, true, function (err, result) {
      if (err) {
        reject(err);
      } else {
        console.log(JSON.stringify(result));
        resolve({ result });
      }
    });
  });
};
export const resendEmail = async (username) => {
  var userData = {
    Username: username,
    Pool: userPool,
  };
  var cognitoUser = new CognitoUser(userData);
  return new Promise((resolve, reject) => {
    cognitoUser.resendConfirmationCode(function (err, result) {
      if (err) {
        reject(err);
      } else {
        console.log(JSON.stringify(result));
        resolve({ result });
      }
    });
  });
};
export const newPassword = async (email, code, password) => {
  var userData = {
    Username: email,
    Pool: userPool,
  };
  var cognitoUser = new CognitoUser(userData);
  cognitoUser.confirmPassword(code, password, {
    onSuccess() {
      console.log("Password confirmed!");
    },
    onFailure(err) {
      console.log("Password not confirmed!");
    },
  });
};
export const resetPassword = async (email) => {
  var userData = {
    Username: email,
    Pool: userPool,
  };
  var cognitoUser = new CognitoUser(userData);
  cognitoUser.forgotPassword({
    onSuccess: function (data) {
      // successfully initiated reset password request
      console.log("CodeDeliveryData from forgotPassword: " + data);
    },
    onFailure: function (err) {
      console.log(err.message || JSON.stringify(err));
    },
    //Optional automatic callback
    inputVerificationCode: function (data) {
      console.log("Code sent to: " + data.CodeDeliveryDetails.Destination);
    },
  });
};
