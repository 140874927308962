import React, { useEffect, useState } from "react";
import { authenticate, registerUser } from "./AuthService";
import logo from "../assets/images/logo.svg";
import { useNavigate, useSearchParams } from "react-router-dom";

const SignUp = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");

  const [password, setPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumer, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const [allRequirementsMet, setAllRequirementsMet] = useState(false);
  const [requirements, setRequirements] = useState({
    pwlength: false,
    number: false,
    lowerCase: false,
    upperCase: false,
    specialChar: false,
    pwMatch: false,
  });
  const handleLogin = async () => {
    try {
      const session = await registerUser(email, phoneNumer, username, password);
      console.log("Login successful:", session);
      setError("Authentification succeded!");
      const uri = encodeURI(`/confirm?email=${email}&username=${username}`);
      navigate(uri);
    } catch (err) {
      setError(err.message);
      console.error("Login failed:", err);
    }
  };
  const pwCheck = () => {
    const updates = {
      pwlength: password.length > 8 && password.length < 32,
      upperCase: /[A-Z]/.test(password),
      lowerCase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      specialChar:
        /[\!\@\#\$\%\^\&\*\(\)\_\+\-\=\[\]\{\}\;\:\'\"\,\<\.\>\?\/\`\~]/.test(
          password
        ),
      pwMatch: password === confirmPassword,
    };
    console.log(updates);
    setRequirements((prevReq) => ({
      ...prevReq,
      ...updates,
    }));
  };

  useEffect(() => {
    pwCheck();
  }, [password, confirmPassword]);
  useEffect(() => {
    const requirementsValues = Object.values(requirements);
    if (requirementsValues.every((value) => value === true)) {
      setAllRequirementsMet(true);
    } else {
      setAllRequirementsMet(false);
    }
    console.log(requirements);
  }, [requirements]);
  return (
    <div className="login">
      <div className="logo_div">
        <img src={logo} className="logo" alt="LingThgins' logo" />
      </div>
      <p className="loginText">Create an account</p>
      <label className="email_label labels" for="username">
        Username*
      </label>
      <input
        id="username"
        type="text"
        className="input"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        placeholder=""
      />
      <label className="email_label labels" for="email">
        Email*
      </label>
      <input
        id="email"
        className="input"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder=""
      />
      <label className="email_label labels" for="phone">
        Phone number
      </label>
      <input
        id="phone"
        className="input"
        type="tel"
        value={phoneNumer}
        onChange={(e) => setPhoneNumber(e.target.value)}
        placeholder=""
      />
      <label className="email_label labels" for="pw">
        Password*
      </label>
      <input
        id="pw"
        className="input"
        type="password"
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
        placeholder=""
      />
      {password && !allRequirementsMet && (
        <div className="password_requirements">
          <p className={requirements.pwlength ? "req_met" : "req"}>
            Password must be between 8 and 32 characters
          </p>
          <p className={requirements.number ? "req_met" : "req"}>
            Password must contain at least one number
          </p>
          <p className={requirements.upperCase ? "req_met" : "req"}>
            Password must contain at least one uppercase letter
          </p>
          <p className={requirements.lowerCase ? "req_met" : "req"}>
            Password must contain at least one lowercase letter
          </p>
          <p className={requirements.specialChar ? "req_met" : "req"}>
            Password must contain at least one special character
          </p>
          <p className={requirements.pwMatch ? "req_met" : "req"}>
            Both passwords must match
          </p>
        </div>
      )}
      {allRequirementsMet && (
        <div className="password_requirements">
          <p className="req_met">Password meets the requirements</p>
        </div>
      )}
      <label className="email_label labels" for="confirm_pw">
        Confirm password*
      </label>
      <input
        id="pw"
        className="input"
        type="password"
        value={confirmPassword}
        onChange={(e) => {
          setconfirmPassword(e.target.value);
          pwCheck();
        }}
        placeholder=""
      />

      {error && <p className="error">{error}</p>}
      <button
        disabled={allRequirementsMet && username ? false : true}
        className="signup_button"
        onClick={handleLogin}
      >
        Sign Up
      </button>
      <button onClick={() => navigate("/login")} className="register_button">
        Already have an account?
      </button>
    </div>
  );
};

export default SignUp;
