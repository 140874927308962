import React, { useState, useEffect } from "react";
import { authenticate, resetPassword } from "./AuthService";
import logo from "../assets/images/logo.svg";
import { useNavigate, useSearchParams } from "react-router-dom";

const Login = () => {
  var isNewPassword = false;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [newPW, setnewPW] = useState("");

  const [code, setCode] = useState("");
  const [error, setError] = useState("");

  const handlePassword = async () => {
    try {
      const session = await resetPassword(username);
      console.log("Login successful:", session);
      isNewPassword = true;
      setError("Authentification succeded!");
    } catch (err) {
      setError(err.message);
      console.error("Login failed:", err);
    }
  };
  const handleLogin = async () => {
    try {
      const session = await authenticate(username, password);
      console.log("Login successful:", session);
      window.location.replace("https://lingthings.net");
      setError("Authentification succeded!");
    } catch (err) {
      setError(err.message);
      console.error("Login failed:", err);
    }
  };
  useEffect(() => {
    console.log("ok");
    setUsername(searchParams.get("username"));
    console.log(searchParams.get("username"));
  }, []);

  return (
    <div className="login">
      <div className="logo_div">
        <img src={logo} className="logo" alt="LingThgins' logo" />
      </div>
      <p className="loginText">I have an account</p>
      <label className="email_label labels" for="email">
        Email or username
      </label>
      <input
        className="input email"
        type="text"
        id="email"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        placeholder="Username or email"
      />
      <label className="password_label labels" for="password">
        Password
      </label>
      <input
        id="password"
        className="input password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
      />
      <button
        className="forgot_pw"
        onClick={() => {
          navigate("/forgot");
        }}
      >
        Forgot my password
      </button>
      {error && <p className="error">{error}</p>}
      <button className="loginButton" onClick={handleLogin}>
        Login
      </button>
      <button
        onClick={() => {
          navigate("/register");
        }}
        className="register_button"
      >
        No account yet?
      </button>
    </div>
  );
};

export default Login;
